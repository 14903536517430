'use strict';

window.console || (window.console = {
	log: function log() {}
});
var NSFD = window.NSFD || null;
var cssua = window.cssua || null;

(function ($) {
	var siteName = '';
	var a = 'is-active';
	var v = 'is-visible';
	var f = 'is-fixed';
	var slideSpeed = '400';
	var arrowPrev = '<button type="button" class="slick-prev"><svg class="icon"><use xlink:href="/index/common/svg/sprite.svg#arrow_left" /></svg></button>';
	var arrowNext = '<button type="button" class="slick-next"><svg class="icon"><use xlink:href="/index/common/svg/sprite.svg#arrow_right" /></svg></button>';

	NSFD = {
		va: {
			pathname: location.pathname,
			device: {
				desktop: 1024,
				sp: 768
			},
			window: {
				width: $(window).width(),
				height: $(window).height()
			}
		},
		localDecision: function localDecision() {
			var regExp = new RegExp('(test\\.' + siteName + '\\.co\\.jp|copre\\.jp|localhost|192\\.168\\.0\\.|192\\.168\\.11\\.|192\\.168\\.128\\.)', 'g');
			return regExp.test(location.hostname);
		},
		localLoading: function localLoading() {
			var _this = this;
			// ローカル環境ならincludeをAjaxで読み込む
			var key = 'inc_';
			var ajaxArray = [];
			var ajaxArrayList = [];
			var i, j;
			var includeClass = document.querySelectorAll('[class*="' + key + '"]');
			var includeClassLen = includeClass.length;

			for (i = 0; i < includeClassLen; i++) {
				var path = includeClass[i].innerHTML.split(' ')[1];
				ajaxArray.push(path);
			}
			var ajaxLen = ajaxArray.length;

			if (ajaxLen > 0) {
				for (i = 0; i < ajaxLen; i++) {
					ajaxArrayList[i] = $.ajax({
						type: 'GET',
						url: ajaxArray[i] + '.html',
						dataType: 'html',
						timeout: 5000
					});
				}
				$.when.apply($, ajaxArrayList).done(function () {
					for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
						args[_key] = arguments[_key];
					}

					var regExp = new RegExp(key);

					for (i = 0; i < args.length; i++) {
						var result = args[i];
						var position = $(result[0]).filter(':first').attr('class').split(' ');

						for (j = 0; j < position.length; j++) {
							if (position[j].match(regExp)) {
								position = position[j];
								break;
							}
						}
						$('.' + position).html(result[0]).children().unwrap();

						console.log('Succeeded to read the include file!:', position);
					}
					_this.loadDelayScript();
				});
			} else {
				_this.loadDelayScript();
			}
		},
		catchAjaxError: function catchAjaxError(_errorThrown) {
			if (window.ga) {
				window.ga('send', 'event', 'ajaxError', location.href, _errorThrown, 0, true);
			}
		},
		getQuery: function getQuery() {
			var vars = [];
			var hash = null;
			var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
			for (var i = 0; i < hashes.length; i++) {
				hash = hashes[i].split('=');
				vars.push(hash[0]);
				vars[hash[0]] = hash[1];
			}
			return vars;
		},
		nextToggle: function nextToggle() {
			var $switch = $('.js-toggle');

			$switch.on('click', function () {
				$(this).toggleClass(a);
				$(this).next('.js-toggleBlock').stop().slideToggle(slideSpeed);
			});
		},
		easeScroll: function easeScroll() {
			$('a[rel="scroll"]').on('click', function (event) {
				var speed = 1200;
				if (/#/.test(event.currentTarget.href)) {
					scrollToObject(event.currentTarget.href, speed);
				}
				return false;
			});
			function scrollToObject(_self, _speed) {
				var hash = '#' + _self.split('#')[1];
				var pos = hash === '#pagetop' ? 0 : $(hash).offset().top;

				if (hash !== '#pagetop') {
					if (NSFD.va.window.width < NSFD.va.device.sp) {
						pos -= 127;
					} else {
						pos -= 80;
					}
				}

				$('html,body').stop().animate({ scrollTop: pos }, _speed, 'easeOutExpo');
			}
		},
		easeScrollHash: function easeScrollHash() {
			var hash = location.hash;
			if (hash !== '') {
				$(window).on('load', function () {
					$('html, body').css('display', 'none');

					setTimeout(function () {
						$('html, body').css('display', 'block');

						var _hash = '#' + hash.split('#')[1];
						var pos = $(_hash).offset().top;

						if (NSFD.va.window.width < NSFD.va.device.sp) {
							pos -= 127;
						} else {
							pos -= 80;
						}
						$('html,body').stop().animate({ scrollTop: pos }, 1200, 'easeOutExpo');
					}, 100);
				});
			}
		},
		pageTop: function pageTop() {
			var $pagetop = $('.footer-pagetop');

			$(window).scroll(function (e) {
				var $this = $(e.currentTarget);
				var $footer = $('.footer');
				// var footerPos = $footer.offset().top + $footer.height();
				var thisScroll = $this.scrollTop();
				// var pagetopPos = $this.height() + $footer.height() + thisScroll;

				if (thisScroll > '150') {
					$pagetop.addClass(v);
				} else {
					$pagetop.removeClass(v);
				}
			});
		},
		headerMenu: function headerMenu() {
			// スクロール挙動
			var header = $('.header');
			var startPos = 0;
			$(window).scroll(function (e) {
				var $this = $(e.currentTarget);
				var thisScroll = $this.scrollTop();

				if (thisScroll >= 200 && thisScroll > startPos) {
					header.addClass('is-small');
				} else {
					header.removeClass('is-small');
				}
				startPos = thisScroll;
			});

			// 検索
			var searchBtn = $('.header-search-btn');
			var searchOverlay = $('.header-search-overlay');
			var o = 'is-open';
			searchBtn.on('click', function (e) {
				$('.header-search').addClass(o);
			});
			searchOverlay.on('click', function (e) {
				$('.header-search').removeClass(o);
			});

			// <SP>ヘッダーメニュー開閉
			var scrollTopNow = void 0;
			var menu = $('.header-menu');
			var menuBtn = $('.header-menuBtn');
			var menuOverlay = $('.header-menu-overlay');
			$('.header-menuBtn, .header-menu-overlay').on('click', function (e) {
				menuBtn.toggleClass(a);
				menu.toggleClass(a);

				if ($('.header-menuBtn').hasClass(a)) {
					fixedOnContainer();
				} else {
					fixedOffContainer();
				}
			});

			var fixedOnContainer = function fixedOnContainer() {
				scrollTopNow = $(window).scrollTop();
				$('.container').css({
					position: 'fixed',
					width: '100%',
					top: -1 * scrollTopNow,
					left: 0
				});
				$('body').addClass(f);
			};
			var fixedOffContainer = function fixedOffContainer() {
				$('.container').css({
					position: 'static'
				});
				$('body').removeClass(f);
				$(window).scrollTop(scrollTopNow);
			};
		},
		tabChange: function tabChange() {
			if ($('.mod-tab').length) {
				if ($('.mod-tab').eq(0).hasClass('js-tabChange')) {
					var $hash = location.hash;
					if ($hash.length) {
						$('.mod-tab-btn').each(function (idx, ele) {
							if ($(ele).attr('data-name') === $hash.replace('#', '')) {
								$(ele).addClass(a);
								$('.mod-tab-content').eq(idx).addClass(a);
							}
						});
					} else {
						$('.mod-tab-content').eq(0).addClass(a);
						$('.mod-tab-btn').eq(0).addClass(a);
					}
					$('.mod-tab-btn').each(function (idx, val) {
						$(val).on('click', function () {
							$('.mod-tab-btn').removeClass(a);
							$(val).addClass(a);
							$('.mod-tab-content').removeClass(a);
							$('.mod-tab-content').eq(idx).addClass(a);
						});
					});
				}
			}
		},
		sideNav: function sideNav() {
			if ($('.mod-sideNav-nav').length) {
				$('.mod-sideNav-nav-list-li-a').each(function (idx, ele) {
					var $href = $(ele).attr('href');
					if (NSFD.va.pathname === $href) {
						$(ele).addClass(a);
					}
				});
			}
		},
		recommendSlider: function recommendSlider() {
			// おすすめ商品サイト
			$(document).ready(function () {
				if ($('.prod-idx-recommend-slider').length) {
					$('.prod-idx-recommend-slider').slick({
						infinite: true,
						speed: 1500,
						autoplay: true,
						autoplaySpeed: 5000,
						slidesToShow: 4,
						arrows: true,
						dots: false,
						prevArrow: arrowPrev,
						nextArrow: arrowNext,
						responsive: [{
							breakpoint: 768,
							settings: {
								slidesToShow: 1,
								appendArrows: $('.prod-idx-recommend-slider-arrows')
							}
						}]
					});
					$('.mod-boxLink03-txtBlock-tit').matchHeight();
					$('.mod-boxLink03-txtBlock-txt').matchHeight();
					$('.prod-idx-recommend-slider').addClass(v);
				}
			});
		},
		siteSearch: function siteSearch() {
			(function () {
				var s = document.createElement('script');
				s.src = '/index/common/js/search.js';
				document.getElementsByTagName('body')[0].appendChild(s);
			})();
		},
		loadDelayScript: function loadDelayScript() {
			var _this = this;
			_this.nextToggle();
			_this.easeScroll();
			_this.pageTop();
			_this.headerMenu();
			_this.tabChange();
			_this.sideNav();
			_this.easeScrollHash();
			_this.recommendSlider();
			_this.siteSearch();
			svg4everybody();
		}
	};

	$(function () {
		return NSFD.localDecision() ? NSFD.localLoading() : NSFD.loadDelayScript();
	});
})(window.jQuery);